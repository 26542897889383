/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/destructuring-assignment */
import useWorkflows from '../hooks/useWorkflows';
import SubNav from '../components/Workflows/Builder/Elements/SubNav';
import WorkflowsGrid from '../components/Workflows/WorkflowsGrid';
import ELayerMain from '../components/Workflows/Builder/ELayer';
import TLayerMain from '../components/Workflows/Builder/TLayer';
import LLayerMain from '../components/Workflows/Builder/LLayer';
import AdaptersMain from '../components/Workflows/Builder/Adapters';
import TargetValueModal from '../components/Workflows/Builder/Elements/TargetValueModal';
import ConfirmModal from '../components/Base/Elements/Confirmations/ConfirmModal';
import TemplatesModal from '../components/Workflows/Builder/Elements/TemplatesModal';

interface WorkflowsProps {
  selectedWorkspace: any;
  selectedEnvironment: any;
  user: any;
  admin: boolean;
  notify: () => void;
  logout: () => void;
  updateBillingCustomer: () => void;
  setSelectedEnvironment: () => void;
  account: any;
  accounts: any[];
  environments: any[];
}

export default function Workflows(props: WorkflowsProps) {
  const {
    ux,
    setUx,
    error,
    copied,
    pending,
    lookUpModal,
    selectedField,
    selectedFieldMap,
    selectedDefaultValue,
    triggerSample,
    triggerMap,
    showEnvironmentDropdown,
    trigger,
    next,
    sampleMap,
    sampleMessageInput,
    targets,
    fieldMapGrid,
    eventTriggerGrid,
    sampleFilter,
    workflow,
    setWorkflow,
    currentLookups,
    showConfirmModal,
    setShowConfirmModal,
    showMessageIDModal,
    setShowMessageIDModal,
    messageEvent,
    fhirMap,
    fhirLoading,
    fhirSampleMap,
    showTemplatesModal,
    setShowTemplatesModal,
    templates,
    tunnels,
    hydrateMappingGrids,
    updateFieldMap,
    loadWorkflow,
    saveWorkflow,
    saveTemplate,
    isStarted,
    handleFlowNameChange,
    handleFlowDescriptionChange,
    handleSampleMessageChange,
    handleNextS3BucketName,
    handleNextS3Key,
    handleNextTunnel,
    createS3Bucket,
    handleNextUrl,
    handleNextIP,
    handleNextPort,
    validatePayload,
    updateFieldMapKey,
    updateFieldMapValue,
    handleLookupDefaultValueChange,
    toggleLookUpModal,
    addRowToLookupsHandler,
    removeRow,
    removeFieldMapRow,
    updateTrigger,
    removeTriggerMapRow,
    setCsvFileHandler,
    clearToggle,
    newWorkflowClickHandler,
    backToListClickHandler,
    eventTriggerClickHandler,
    adapterClickHandler,
    mapClickHandler,
    loadClickHandler,
    enableSampleFilter,
    updateEventTrigger,
    updateActionTrigger,
    fetchFHIRR4Map,
    updateHL7ActionTrigger,
    parseJSONSampleMessage,
    setCopiedHandler,
    clearError,
    showEnvironmentDropdownHandler,
    showSampleMessageInput,
    updateTriggerMethod,
    onChangeHost,
    onChangeUsername,
    onChangePort,
    onChangePath,
    onChangeCadence,
    onChangeCert,
    onChangePrivateKey,
    showTemplates,
    updateIcon,
    startFromTemplate,
    loading,
    placeholder,
    hl7v2EventTofhirr4MethodMap,
    toggleActive,
  } = useWorkflows(props);

  if (!props.selectedWorkspace || !props.selectedEnvironment) {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body text-center">
                  <h1 className="display-4">No Workspace Selected</h1>
                  <p className="lead">Please select a workspace from the dropdown above.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (ux === 'list') {
    return (
      <>
        {showTemplatesModal && (
          <TemplatesModal
            isOpen={showTemplatesModal}
            templates={templates}
            applyTemplate={loadWorkflow}
            saveTemplate={saveTemplate}
            saveButton={false}
            closeModal={() => setShowTemplatesModal(false)}
          />
        )}
        <WorkflowsGrid
          user={props.user}
          account={props.account}
          accounts={props.accounts}
          environments={props.environments}
          setShowEnvironmentDropdown={showEnvironmentDropdownHandler}
          selectedEnvironment={props.selectedEnvironment}
          selectedWorkspace={props.selectedWorkspace}
          setSelectedEnvironment={props.setSelectedEnvironment}
          newWorkflowClickHandler={newWorkflowClickHandler}
          loadWorkflow={loadWorkflow}
          logout={props.logout}
          showEnvironmentDropdownHandler={showEnvironmentDropdownHandler}
          showEnvironmentDropdown={showEnvironmentDropdown}
          admin={props.admin}
          updateBillingCustomer={props.updateBillingCustomer}
          showTemplates={showTemplates}
          notify={props.notify}
        />
      </>
    );
  }
  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          isOpen={showConfirmModal}
          message="You have unsaved changes. Are you sure you want to go back?"
          title="Go Back?"
          onConfirm={() => {
            setWorkflow(placeholder);
            setUx('list');
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      {showTemplatesModal && (
        <TemplatesModal
          isOpen={showTemplatesModal}
          templates={templates}
          applyTemplate={startFromTemplate}
          saveTemplate={saveTemplate}
          saveButton={true}
          closeModal={() => setShowTemplatesModal(false)}
        />
      )}
      {showMessageIDModal && (
        <ConfirmModal
          isOpen={showMessageIDModal}
          message="Your sample message doesn't contain your workspace's unique message identifier. Are you sure you want to save?"
          title="Unique Message Identifier"
          onConfirm={() => {
            saveWorkflow();
            setShowMessageIDModal(false);
          }}
          onClose={() => setShowMessageIDModal(false)}
        />
      )}
      <SubNav
        isStarted={isStarted}
        workflow={workflow}
        backToListClickHandler={backToListClickHandler}
        saveWorkflow={saveWorkflow}
        pending={pending}
        environments={props.environments}
        setShowEnvironmentDropdown={showEnvironmentDropdownHandler}
        showEnvironmentDropdownHandler={showEnvironmentDropdownHandler}
        showEnvironmentDropdown={showEnvironmentDropdown}
        user={props.user}
        accounts={props.accounts}
        selectedEnvironment={props.selectedEnvironment}
        account={props.account}
        selectedWorkspace={props.selectedWorkspace}
        setSelectedEnvironment={props.setSelectedEnvironment}
        loadClickHandler={loadClickHandler}
        mapClickHandler={mapClickHandler}
        adapterClickHandler={adapterClickHandler}
        eventTriggerClickHandler={eventTriggerClickHandler}
        handleFlowNameChange={handleFlowNameChange}
        placeholder={placeholder}
        loading={loading}
        fhirLoading={fhirLoading}
        ux={ux}
        saveTemplate={saveTemplate}
      />

      {ux === 'adapters' && (
        <AdaptersMain
          workflow={workflow}
          setWorkflow={setWorkflow}
          placeholder={placeholder}
          selectedEnvironment={props.selectedEnvironment}
          adapterClickHandler={adapterClickHandler}
          updateEventTrigger={updateEventTrigger}
          sampleMessageInput={sampleMessageInput}
          handleSampleMessageChange={handleSampleMessageChange}
          selectedWorkspace={props.selectedWorkspace}
          updateActionTrigger={updateActionTrigger}
          updateHL7ActionTrigger={updateHL7ActionTrigger}
          parseJSONSampleMessage={parseJSONSampleMessage}
          error={error}
          handleFlowNameChange={handleFlowNameChange}
          clearError={clearError}
          toggleActive={toggleActive}
          showTemplates={showTemplates}
          updateIcon={updateIcon}
          handleFlowDescriptionChange={handleFlowDescriptionChange}
        />
      )}
      {ux === 'e-layer' && (
        <ELayerMain
          workflow={workflow}
          clearError={clearError}
          environments={props.environments}
          error={error}
          triggerMap={triggerMap}
          enableSampleFilter={enableSampleFilter}
          sampleFilter={sampleFilter}
          messageEvent={messageEvent}
          hl7v2EventTofhirr4MethodMap={hl7v2EventTofhirr4MethodMap}
          updateTriggerMethod={updateTriggerMethod}
          showSampleMessageInput={showSampleMessageInput}
          selectedEnvironment={props.selectedEnvironment}
          selectedWorkspace={props.selectedWorkspace}
          triggerSample={triggerSample}
          eventTriggerGrid={eventTriggerGrid}
          trigger={trigger}
          updateEventTrigger={updateEventTrigger}
          showSampleTriggerGrid={showSampleMessageInput}
          updateTrigger={updateTrigger}
          loading={loading}
          removeTriggerMapRow={removeTriggerMapRow}
          onChangeHost={onChangeHost}
          onChangeUsername={onChangeUsername}
          onChangePort={onChangePort}
          onChangePath={onChangePath}
          onChangeCadence={onChangeCadence}
          onChangeCert={onChangeCert}
          onChangePrivateKey={onChangePrivateKey}
        />
      )}
      {ux === 't-layer' && (
        <TLayerMain
          error={error}
          clearError={clearError}
          workflow={workflow}
          loading={loading}
          sampleFilter={sampleFilter}
          enableSampleFilter={enableSampleFilter}
          selectedWorkspace={props.selectedWorkspace}
          fieldMapGrid={fieldMapGrid}
          fhirMap={fhirMap}
          removeFieldMapRow={removeFieldMapRow}
          updateFieldMap={updateFieldMap}
          toggleLookUpModal={toggleLookUpModal}
          hydrateMappingGrids={hydrateMappingGrids}
          validatePayload={validatePayload}
          targets={targets}
          fetchFHIRR4Map={fetchFHIRR4Map}
          fhirLoading={fhirLoading}
        />
      )}
      {ux === 't-layer' && (
        <TargetValueModal
          workflow={workflow}
          toggleLookUpModal={toggleLookUpModal}
          selectedField={selectedField}
          handleLookupDefaultValueChange={handleLookupDefaultValueChange}
          selectedFieldMap={selectedFieldMap}
          addRowToLookupsHandler={addRowToLookupsHandler}
          setCsvFile={setCsvFileHandler}
          selectedDefaultValue={selectedDefaultValue}
          selectedWorkspace={props.selectedWorkspace}
          removeRow={removeRow}
          clearToggle={clearToggle}
          updateFieldMapKey={updateFieldMapKey}
          updateFieldMapValue={updateFieldMapValue}
          open={lookUpModal}
          validatePayload={validatePayload}
          saveWorkflow={saveWorkflow}
          currentLookups={currentLookups}
        />
      )}
      {ux === 'l-layer' && (
        <LLayerMain
          error={error}
          clearError={clearError}
          updateActionTrigger={updateActionTrigger}
          workflow={workflow}
          next={next}
          handleNextUrl={handleNextUrl}
          sampleMap={sampleMap}
          handleNextIP={handleNextIP}
          handleNextPort={handleNextPort}
          selectedEnvironment={props.selectedEnvironment}
          selectedWorkspace={props.selectedWorkspace}
          setCopiedHandler={setCopiedHandler}
          copied={copied}
          createS3Bucket={createS3Bucket}
          handleNextS3BucketName={handleNextS3BucketName}
          handleNextS3Key={handleNextS3Key}
          fhirSampleMap={fhirSampleMap}
          tunnels={tunnels}
          handleNextTunnel={handleNextTunnel}
        />
      )}
    </>
  );
}
