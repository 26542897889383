import React from "react";

export default function FlowHeading(props: any) {
  return (
    <div className="w-full mt-2 inline px-2 mx-auto">
      <button type="button" className="text-xl leading-6 font-semibold text-gray-600 dark:text-cyan-300">
        {props.title}
      </button>
    </div>
  );
}
