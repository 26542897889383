/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import EnvironmentForm from './EnvironmentForm';
import EnvironmentList from './EnvironmentList';
import { useEnvironment } from '../../context/EnvironmentContext';
import aws from '../../../images/aws2.png';

export default function EnvironmentContent({
  setShowCreateForm,
  showCreateForm,
}: {
  setShowCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
  showCreateForm: boolean;
}) {
  const { hydrateAllEnvironments } = useEnvironment();

  React.useEffect(() => {
    hydrateAllEnvironments();
  }, [showCreateForm]);

  return (
    <div>
      <div className="flex items-center justify-between bg-cyan-400 p-2">
        <img className="h-8 w-auto" src={aws} alt="AWS" />
        <div className="flex items-center space-x-2">
          {!showCreateForm && (
            <button
              type="button"
              id="create-environment"
              onClick={() => setShowCreateForm(!showCreateForm)}
              className="bg-purple-400 border-purple-500 border rounded text-cyan-100 text-xs hover:text-white px-3 py-1"
            >
              Create
            </button>
          )}
          {showCreateForm && (
            <button
              type="button"
              id="cancel-environment"
              onClick={() => setShowCreateForm(!showCreateForm)}
              className="bg-purple-400 border-purple-500 border rounded text-cyan-100 text-xs hover:text-white px-3 py-1"
            >
              Cancel
            </button>
          )}
          <button
            type="button"
            id="refresh-environments"
            onClick={() => hydrateAllEnvironments()}
            className="text-purple-400 hover:text-purple-500 hover:font-bold px-2 rounded"
          >
            <ArrowPathIcon className="h-6 w-6" />
          </button>
        </div>
      </div>

      {showCreateForm ? (
        <div className="p-4">
          <EnvironmentForm setShowCreateForm={setShowCreateForm} />
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-8 p-4">
          <EnvironmentList />
        </div>
      )}
    </div>
  );
}
