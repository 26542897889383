/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from 'react';
import { TrashIcon, InformationCircleIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import MessageIdentifierInfoAlert from '../../Base/Elements/Alerts/MessageIdentifierInfoAlert';
import FlowHeading from './Elements/FlowHeading';
import Toggle from './Elements/GridToggle';
import Dropdown from './Elements/WorkflowDropDown';
import SFTPConfigModal from './Elements/SFTPConfigModal';

export default function ELayer(props: any) {
  const selectedEnvironment = props.selectedEnvironment ? props.selectedEnvironment : null;
  const [eventTriggerGrid, setEventTriggerGrid] = useState(props.eventTriggerGrid);
  const [showMessageIdInfo, setShowMessageIdInfo] = useState(false);
  const [SFTPTriggerModal, setSFTPTriggerModal] = useState(false);
  const closeModal = () => {
    setSFTPTriggerModal(false);
  };

  useEffect(() => {
    setEventTriggerGrid(props.eventTriggerGrid);
  }, [props.eventTriggerGrid]);

  return (
    <div className="py-4">
      {props.error == 'SaveInAnotherAccountException' && <SaveInAnotherAccountAlert closeAlert={() => props.clearError()} />}
      {props.error == 'UserPermissionsException' && <UserPermissionsAlert closeAlert={() => props.clearError()} />}
      {showMessageIdInfo && <MessageIdentifierInfoAlert closeAlert={() => setShowMessageIdInfo(false)} />}
      {SFTPTriggerModal && (
        <SFTPConfigModal
          isOpen={SFTPTriggerModal}
          onChangeHost={props.onChangeHost}
          onChangeUsername={props.onChangeUsername}
          onChangePassword={props.onChangePassword}
          onChangePort={props.onChangePort}
          onChangePath={props.onChangePath}
          onChangeCadence={props.onChangeCadence}
          workflow={props.workflow}
          closeModal={closeModal}
          onChangeCert={props.onChangeCert}
          onChangeKey={props.onChangeKey}
          onChangePrivateKey={props.onChangePrivateKey}
        />
      )}
      <div className="inline">
        <div className="px-2 md:mt-0">
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="dark:bg-gray-800 rounded-md space-y-4 px-6 pt-6 mx-6 pb-6 border-solid border-2 shadow border-cyan-300">
              <div className="inline-flex w-full">
                <FlowHeading title={`${props.workflow?.flowName} Extract Event`} />
                {(props.workflow.trigger.type.substring(0, 4) === 'MLLP' || props.workflow.trigger.type.substring(0, 4) === 'SFTP') &&
                  props.workflow.next.type === 'HTTPS FHIR R4' && (
                    <div className="px-auto">
                      <Dropdown
                        selectHandler={(event: any) => props.updateTriggerMethod(event)}
                        selected={props.workflow?.next?.method}
                        Types={props.hl7v2EventTofhirr4MethodMap}
                      />
                    </div>
                  )}
                {props.workflow.trigger.type.substring(0, 4) == 'SFTP' && (
                  <div
                    onClick={() => {
                      setSFTPTriggerModal(!SFTPTriggerModal);
                    }}
                    className="inline-flex items-center mx-2 border-2 rounded-md px-2 border-gray-200 cursor-pointer hover:bg-cyan-500"
                  >
                    <Cog6ToothIcon className="h-5 w-5 dark:text-gray-200 text-gray-600 inline" />
                    <h2 className="button text-sm dark:text-gray-200 text-gray-600 px-2 py-1">SFTP</h2>
                  </div>
                )}
                {props.workflow.trigger.type.split(' ')[1] == 'HL7v2' && (
                  <div className="inline-flex items-center px-2 py-1 mx-1 mr-2 border-2 border-cyan-400 dark:dark:text-gray-`00 text-gray-600 rounded-md shadow-sm text-xs dark:bg-gray-800 focus:outline-none">
                    <span className="text-sm">Selected&nbsp;&nbsp;</span>
                    <Toggle enabled={props.sampleFilter} setEnabled={() => props.enableSampleFilter()} />
                    <span className="text-sm">&nbsp;&nbsp;All</span>
                  </div>
                )}
              </div>
              <div className="border border-top border-cyan-300 mx-2" />
              {(props.workflow.trigger.type.substring(0, 4) == 'MLLP' || props.workflow.trigger.type.substring(0, 4) == 'SFTP') && (
                <div>
                  <table className="min-w-full divide-y divide-cyan-400 border-2 border-cyan-400 shadow">
                    <thead className="dark:bg-gray-900">
                      <tr>
                        <th scope="col" className="pl-4 pr-2 py-2 text-left text-s font-medium text-cyan-300 uppercase tracking-wider">
                          Field
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-s font-medium text-cyan-300 uppercase tracking-wider">
                          Specification
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-m font-medium text-cyan-300 tracking-wider">
                          SAMPLE
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-m font-medium text-cyan-300 tracking-wider">
                          FILTER
                          <InformationCircleIcon
                            className="h-5 w-5 pb-1 inline align-middle text-pink-300 rounded cursor-pointer hover:text-pink-500"
                            onClick={() => {
                              setShowMessageIdInfo(true);
                            }}
                            closeAlert={() => {
                              setShowMessageIdInfo(false);
                            }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedEnvironment.accountId != '411790770360' && props.triggerSample == props.selectedWorkspace.hash.value && (
                        <tr key="unique-message-id" className="dark:bg-gray-800 border-2 border-solid border-green-400 hover:bg-cyan-500">
                          <td className="pl-4 pr-2 py-2 whitespace-nowrap text-sm dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.location}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs font-light dark:text-gray-200 text-gray-600">Unique Message Identifier</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-`00 text-gray-600">{props.triggerSample}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.value}</td>
                        </tr>
                      )}
                      {selectedEnvironment.accountId != '411790770360' && props.triggerSample != props.selectedWorkspace.hash.value && (
                        <tr key="unique-message-id" className="dark:bg-gray-800 border-2 border-solid border-pink-400 hover:bg-pink-500">
                          <td className="pl-4 pr-2 py-2 whitespace-nowrap text-sm dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.location}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs font-light dark:text-gray-200 text-gray-600">Unique Message Identifier</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-`00 text-gray-600">{props.triggerSample}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.value}</td>
                        </tr>
                      )}
                      {eventTriggerGrid.map((map: any, idx: number) => (
                        <tr
                          key={`${map[1].field}-${map[2].sample}${idx}`}
                          className={idx % 2 === 0 ? 'bg-gray-100 dark:bg-gray-700 hover:bg-cyan-500' : 'dark:bg-gray-800 hover:bg-cyan-500'}
                        >
                          <td className="pl-4 pr-2 py-2 whitespace-nowrap text-sm dark:text-gray-200 text-gray-600">{map[1].field}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs font-light dark:text-gray-200 text-gray-600">{map[0].definition}</td>
                          <td className="px-2 py-2 whitespace-wrap text-sm dark:text-gray-`00 text-gray-600">{map[2].sample}</td>
                          <td className="px-2 py-2 whitespace-wrap inline flex text-sm dark:text-gray-`00 text-gray-600">
                            <input
                              type="text"
                              name="filter"
                              id={map[1].field}
                              defaultValue={map[3].filter}
                              onChange={(event) => props.updateTrigger(event)}
                              className="shadow-sm flex focus:ring-cyan-300 focus:border-cyan-300 block w-full sm:text-sm border-cyan-300 border-2 rounded dark:bg-gray-800"
                            />
                            {!props.sampleFilter && (
                              <div
                                className="pl-2 pt-1 inline rounded-md"
                                onClick={() => {
                                  props.removeTriggerMapRow(map);
                                }}
                              >
                                <TrashIcon className="h-8 w-8 inline cursor-pointer text-gray-400 hover:text-pink-400" />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
