/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import logo from '../../../../images/retrohook.png';
import ThemeToggler from './ThemeToggler';

export default function Navigation(props: any) {
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const goToWorkspace = () => {
    props.history.push(`/workspace`);
  };

  return (
    <Disclosure as="nav" className="bg-transparent dark:bg-gray-700">
      {({ open }) => (
        <>
          <div className="max-w-10xl mx-auto px-4">
            <div className="flex items-center justify-between h-14">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <button
                    onClick={() => {
                      props.redirectToURL('');
                    }}
                  >
                    <img className="h-10 inline pr-1" src={logo} alt="logo" />
                    <span className="inline align-middle text-3xl text-gray-600 dark:text-gray-100" style={{ fontFamily: '"Gugi", sans-serif' }}>
                      Retrohook
                    </span>
                    <span className="rounded-sm text-white text-2xs align-sub font-mono font-light inline mx-1 bg-purple-500 py-0.5 px-1">
                      v{props.version.split('.')[0]}.{props.version.split('.')[1]}.{props.version.split('.')[2].substring(0, 5)}
                    </span>
                    <span className="inline text-xs align-middle text-cyan-300 pl-1" />
                  </button>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {props.nav.map((item: any) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        onClick={() => props.updateNav(item.href)}
                        className={classNames(
                          item.current
                            ? 'border-b-cyan-400 border-b-2 text-gray-600 dark:text-white'
                            : 'text-gray-600 dark:text-white hover:border-b-cyan-300 hover:border-b-2',
                          'px-3 py-2 text-sm font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}

                    <a
                      href="https://docs.retrohook.com/docs/intro"
                      target="_blank"
                      className="flex font-medium text-sm text-gray-600 dark:text-white cursor-pointer"
                      rel="noreferrer"
                    >
                      <span className="hover:border-b-cyan-400 px-4 py-2 hover:border-b-2">Docs</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <ThemeToggler />
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative z-40">
                    <div>
                      <Menu.Button className="bg-transparent dark:bg-gray-700 rounded border-2 border-gray-200 dark:border-gray-600 text-gray-500 focus:outline-none">
                        <span className="sr-only">Open user menu</span>
                        <img className="h-8 w-8 hover:w-10 hover:h-10 rounded" src={props.user.picture} alt="" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg py-1 bg-gray-100 dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="Workspace-Select">
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={() => {
                                props.setSelectedWorkspaceModal(true);
                              }}
                              className={classNames(active ? 'bg-cyan-400' : '', 'cursor-pointer block px-4 py-2 truncate text-sm dark:text-gray-100')}
                            >
                              Workspaces
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Workspace">
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={() => {
                                props.updateNav('');
                                goToWorkspace();
                              }}
                              className={classNames(active ? 'bg-cyan-400 w-full' : '', 'cursor-pointer block px-4 py-2 truncate text-sm dark:text-gray-100')}
                            >
                              Settings
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Sign Out">
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={() => {
                                props.logout();
                              }}
                              className={classNames(active ? 'bg-cyan-400 w-full' : '', 'cursor-pointer block px-4 py-2 truncate text-sm dark:text-gray-100')}
                            >
                              Sign Out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-cyan-400 inline-flex items-center justify-center p-2 rounded-md text-cyan-200 hover:text-white hover:bg-cyan-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-400 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden z-50">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {props.nav.map((item: any) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  onClick={() => {
                    props.updateNav(item.href);
                  }}
                  className={classNames(
                    item.current ? 'bg-cyan-400 text-white' : 'text-white hover:bg-cyan-300 hover:bg-opacity-75',
                    'block px-3 py-2 rounded-md text-base font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <a
                href="https://docs.retrohook.com/docs/intro"
                target="_blank"
                className="block px-3 py-2 rounded-md text-base font-medium text-white cursor-pointer"
                rel="noreferrer"
              >
                Docs
              </a>
              <a
                href="https://discord.com/channels/947629811646480414/1112590923893833780"
                target="_blank"
                className="flex font-medium text-sm text-white cursor-pointer"
                rel="noreferrer"
              >
                <span className="hover:bg-cyan-400 hover:bg-opacity-75 px-4 py-2 hover:border-transparent hover:rounded-md">Discord</span>
              </a>
            </div>
            <div className="pt-4 pb-3 border-t border-cyan-500">
              <div className="flex items-center px-5">
                <div className="ml-3">
                  <div className="text-base font-medium text-white">{props.user.name}</div>
                  <div className="text-sm font-medium text-cyan-300">{props.user.email}</div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1 z-50">
                <Disclosure.Button
                  key="Workspace"
                  onClick={() => {
                    props.setSelectedWorkspaceModal(true);
                  }}
                  as="a"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-cyan-300 hover:bg-opacity-75"
                >
                  Workspace
                </Disclosure.Button>
              </div>
              <div className="mt-3 px-2 space-y-1 z-50">
                <Disclosure.Button
                  key="Workspace"
                  onClick={() => {
                    goToWorkspace();
                  }}
                  as="a"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-cyan-300 hover:bg-opacity-75"
                >
                  Settings
                </Disclosure.Button>
              </div>
              <div className="mt-3 px-2 space-y-1 z-50">
                <Disclosure.Button
                  key="Sign Out"
                  onClick={() => {
                    props.handleLogout();
                  }}
                  as="a"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-cyan-300 hover:bg-opacity-75"
                >
                  Sign Out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
