/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { EnvironmentProvider } from '../../context/EnvironmentContext';
import EnvironmentContent from './EnvironmentContent';

export default function Environments(props: any) {
  const { user, account, selectedWorkspace, notify, environmentChanged, setEnvironmentChanged, getAccount } = props;
  const [showCreateForm, setShowCreateForm] = React.useState(false);

  return (
    <EnvironmentProvider
      user={user}
      account={account || user.account}
      selectedWorkspace={selectedWorkspace}
      notify={notify}
      environmentChanged={environmentChanged}
      setEnvironmentChanged={setEnvironmentChanged}
      getAccount={getAccount}
    >
      <EnvironmentContent setShowCreateForm={setShowCreateForm} showCreateForm={showCreateForm} />
    </EnvironmentProvider>
  );
}
