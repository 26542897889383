/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useState, useEffect, useCallback, useMemo, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider } from 'use-http';

import ReconnectingWebSocket from 'reconnecting-websocket';
import qs from 'qs';
import toast, { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './app/context/ThemeContext';
import Navigation from './app/components/Base/Layout/Navigation';
import Footer from './app/components/Base/Layout/Footer';
import { Product } from './app/pages';
import PrivateRoutes from './app/components/Base/Layout/PrivateRoutes';
import WorkspaceSelectModal from './app/components/Base/Layout/NavigationElements/WorkspaceSelectModal';
import ChatwootWidget from './app/components/Base/Layout/ChatWootWidget';
import config from './config';

const WebSocketContext = createContext(null);
export const useWebSocketContext = () => useContext(WebSocketContext);
const notify = (data: { message: string }) => toast(data.message);

export default function App() {
  const history = useHistory();
  const { logout, user, loginWithRedirect, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [version, setVersion] = useState('loading...');
  const [wsClient, setWsClient] = useState(null);
  const [nav, setNav] = useState<any>(config.nav);
  const [account, setAccount] = useState<any>(user?.account);
  const [token, setToken] = useState<any>('');
  const [environments, setEnvironments] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>([]);
  const [selectedWorkspaceModal, setSelectedWorkspaceModal] = useState<any>(false);
  const [updateBillingCustomer, setUpdateBillingCustomer] = useState<any>(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<any>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>();
  const [accountPermissions, setAccountPermissions] = useState<any>(user?.account?.accountPermissions);
  const [environmentChanged, setEnvironmentChanged] = useState<any>(false);
  const setAccountCallback = useCallback(async (selected: any) => setAccount(selected), [setAccount]);
  const setUpdateBilling = useCallback(async (selected: any) => setUpdateBillingCustomer(selected), [setUpdateBillingCustomer]);
  const setSelectedEnvironmentCallback = useCallback(async (selected: any) => setSelectedEnvironment(selected), [setSelectedEnvironment]);

  const updateNav = (href: string) => {
    const newNav = nav.map((item: any) => {
      if (item.href === href) return { ...item, current: true };
      return { ...item, current: false };
    });
    setNav(newNav);
  };
  const redirectToURL = (page: string) => {
    history.push(`/${page}`);
    updateNav(page);
  };
  const handleLogout = () => {
    if (wsClient) wsClient.send(JSON.stringify({ event: 'brb' }));
    // eslint-disable-next-line no-undef
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  const getRetrohookVersion = async () => {
    try {
      const packageJson = await import('@1putthealth/retrohook-utilities/package.json');
      return packageJson.version;
    } catch (error) {
      return 'unknown';
    }
  };
  const setNavHighlight = (pageName: string) => {
    const newNav = nav.map((item: any) => {
      if (item.name === pageName) return { ...item, current: true };
      return { ...item, current: false };
    });
    setNav(newNav);
  };
  const createAccount = async (accountParams: any) => {
    if (user && token) {
      try {
        const res = await fetch(`${config.url}/account/register-account/${accountParams.id}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accountParams }),
        });
        return res.json() || null;
      } catch (err: any) {
        throw new Error(err.message);
      }
    } else {
      notify({ message: 'Error creating account' });
      return null;
    }
  };
  const fetchWorkspace = async (workspaceId: string) => {
    if (user && token.length > 0) {
      try {
        const url = `${config.url}/account/get-workspace/${user.sub}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ workspaceSK: workspaceId }),
        });
        const data = await response.json();
        if (response && response.ok) {
          setEnvironments(selectedWorkspace?.environments.length > 0 ? selectedWorkspace.environments : data.workspace.environments.sort().reverse());
          setSelectedEnvironmentCallback(selectedEnvironment || data?.workspace?.environments[0]);
          return data.workspace;
        }
        return null;
      } catch (error) {
        notify({ message: error.message });
      }
    }
  };
  const setSelectedWorkspaceHandler = async (requested: any) => {
    const workspace = await fetchWorkspace(requested.SK);
    setSelectedWorkspace(workspace);
    setSelectedWorkspaceModal(false);
  };
  const fetchAccount = async () => {
    if (user && token.length > 0) {
      try {
        let location = {
          ip: '',
          city: '',
          state: '',
          country: '',
        };
        const locationResponse = await fetch('https://api.geoapify.com/v1/ipinfo?apiKey=43fce59071bf4235804363c5f18e5f32', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        if (locationResponse) {
          const locationData = await locationResponse.json();
          location = {
            ip: locationData.ip,
            city: locationData.city.name,
            state: locationData.state.name,
            country: locationData.country.name,
          };
        }

        const url = `${config.url}/account/get-account/${user.sub}?ip=${location.ip}&city=${location.city}&state=${location.state}&country=${location.country}`;

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response && response.ok && data.account) {
          setAccount(data.account);
          setSelectedAccount(data.account);
          setAccountPermissions(data.account.permissions);
          const workspace = await fetchWorkspace(data.account.permissions[0].SK);
          setSelectedWorkspace(workspace);
        } else {
          await createAccount({
            id: user.sub,
            email: user.email,
            name: user.name,
            organization: user.nickname,
            location,
          });
          await fetchAccount();
        }
      } catch (error) {
        notify({ message: 'Error fetching account data' });
      }
    }
  };
  const createWSSClient = (userId: string) => {
    const newClient = new ReconnectingWebSocket(config.wssUrl as string);
    newClient.addEventListener('open', () => {
      newClient.send(JSON.stringify({ event: 'hai', userId, token }));
    });

    newClient.addEventListener('message', (messageEvent: any) => {
      const messageData = JSON.parse(messageEvent.data);
      if (messageData.message && !messageData.suppress) notify({ message: messageData.message });
      if (messageData?.environmentChanged) {
        setEnvironmentChanged(true);
      }
    });
    setWsClient(newClient);
    return newClient;
  };
  // Fetch Auth0 token for use with API requests
  useEffect(() => {
    (async () => {
      const tk = await getAccessTokenSilently();
      setToken(tk);
    })();
  }, [getAccessTokenSilently]);

  // Fetch account data when user is authenticated and token is available
  useEffect(() => {
    const fetchAcc = async () => {
      const acc = await fetchAccount();
    };
    const fetchWrk = async () => {
      if (!account || !account.workspacesIds) return;
      const workspaceId = selectedWorkspace ? selectedWorkspace?.SK : account.workspacesIds[0];
      const updated = await fetchWorkspace(workspaceId);
      setSelectedWorkspace(updated);
      console.dir('Selected workspace:', updated);
    };
    if (user) {
      user.token = token;
      user.account = account;
    }
    if (user?.account && user.sub && token && !wsClient) {
      const newClient = createWSSClient(user.sub);
      setWsClient(newClient);
    }
    if (!account && user?.sub && token) fetchAcc();
    if (account && !selectedWorkspace) fetchWrk();
  }, [user?.account, token, account]);

  // Stripe cancel subscription catch
  useEffect(() => {
    const canceled = !!qs.parse(history.location.search, { ignoreQueryPrefix: true }).canceled;
    if (canceled) {
      setUpdateBilling(true);
      history.replace({ search: '' });
    }
  }, [user?.account, history.location.search]);

  // Redirect user to desired page after login
  useEffect(() => {
    const currentPath = history.location.pathname.substring(1);
    if (currentPath) redirectToURL(currentPath);
    setNav(
      nav.map((item: any) => {
        if (item.href === `/${currentPath}`) return { ...item, current: true };
        return { ...item, current: false };
      }),
    );
  }, []);

  // Fetch Retrohook version
  useEffect(() => {
    const getVersion = async () => {
      const retro = await getRetrohookVersion();
      setVersion(retro);
      console.info(`Retrohook version: ${retro}`);
    };
    getVersion();
  }, []);

  const contextValue = () => ({ wsClient, notify, createWSSClient });
  let options = {};
  if (token) options = { headers: { Authorization: `Bearer ${token}` } };
  if (isAuthenticated && !isLoading) {
    return (
      <ThemeProvider>
        <div className="flex flex-col h-screen">
          <ChatwootWidget />
          <WebSocketContext.Provider value={contextValue}>
            <Toaster />
            <Provider url={config.url} options={options}>
              <Navigation
                user={user}
                account={account}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspaceModal={setSelectedWorkspaceModal}
                logout={() => handleLogout()}
                nav={nav}
                history={history}
                updateNav={updateNav}
                redirectToURL={(url: string) => redirectToURL(url)}
                version={version}
              />
              <WorkspaceSelectModal
                user={user}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspaceHandler={setSelectedWorkspaceHandler}
                accountPermissions={accountPermissions}
                closeSelectModal={() => setSelectedWorkspaceModal(false)}
                open={selectedWorkspaceModal}
                getAccount={fetchAccount}
              />
              <PrivateRoutes
                account={account}
                setAccount={setAccountCallback}
                getAccount={fetchAccount}
                environments={environments}
                history={history}
                updateBillingCustomer={updateBillingCustomer}
                selectedEnvironment={selectedEnvironment}
                setupEnvironmentsHandler={setEnvironments}
                setSelectedEnvironment={setSelectedEnvironmentCallback}
                selectedAccount={selectedAccount}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspace={setSelectedWorkspace}
                setSelectedAccount={setSelectedAccount}
                redirectToURL={(url: string) => redirectToURL(url)}
                notify={notify}
                environmentChanged={environmentChanged}
                setEnvironmentChanged={setEnvironmentChanged}
                user={user}
                setEnvironments={setEnvironments}
                fetchAccount={fetchAccount}
                myWorkspace={account?.myWorkspace}
              />
              <Footer />
            </Provider>
          </WebSocketContext.Provider>
        </div>
      </ThemeProvider>
    );
  }
  if (!isAuthenticated) {
    return (
      <Product
        signUp={() =>
          loginWithRedirect({
            appState: { returnTo: '/' },
            authorizationParams: { screen_hint: 'signup', prompt: 'login' },
          })
        }
        login={() => loginWithRedirect()}
        notify={notify}
        setNavHighlight={setNavHighlight}
      />
    );
  }
}
