/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import useFetch from 'use-http';

const awsProductURL = 'https://aws.amazon.com/marketplace/pp/prodview-boo4ccgoad3ae';

const basePermissions = {
  dashboard: { read: true },
  workflows: { create: false, read: false, update: false, delete: false },
  tunnels: { create: false, read: false, delete: false },
  workspace: { admin: false },
};

const newInvite = { firstName: '', lastName: '', email: '', tempPass: '', permissions: basePermissions };

interface Props {
  user: any;
  selectedWorkspace: any;
  selectedEnvironment: any;
  selectedAccount: any;
  admin: boolean;
  notify: () => void;
  setSelectedEnvironment: () => void;
  updateBillingCustomer: () => void;
  logout: () => void;
  account: any;
  environments: any[];
  myWorkspace: any;
}

const useWorkspace = (props: Props) => {
  const { post, get, response, loading } = useFetch();
  const [subUx, setSubUx] = useState('Settings');
  const [error, setError] = useState<string | null>(null);
  const [products, setProducts] = useState<any[]>([]);
  const [contactModal, setContactModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [togglePermissions, setTogglePermissions] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({ permissions: basePermissions, email: '', name: '', id: '' });
  const [invite, setInvite] = useState<any>(newInvite);
  const [customer, setCustomer] = useState({});
  const [billingUX, setBillingUX] = useState('');
  const [loadingType, setLoadingType] = useState('');
  const [quantityPurchased, setQuantityPurchased] = useState(0);
  const [workspace, setWorkspace] = useState<any>(props.myWorkspace);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(props.selectedWorkspace);

  const fetchMyWorkspace = async (SK: string) => {
    const workspaceRequest = await post(`account/get-workspace/${props.user.sub}`, { workspaceSK: SK });
    if (workspaceRequest) {
      return workspaceRequest.workspace;
    }
  };
  const getProducts = async () => {
    const productItems: any = await get(`account/get-stripe-products/${props.user.sub}`);
    if (productItems?.data) {
      const items = productItems.data;
      const promises = items.map((product: any) => {
        if (product.product[0].name === 'Production Plan' && product.product[0].active) {
          product.features = [
            'Everything in Developer Plan +',
            'Create unlimited workflows for streamlined legacy healthcare events.',
            'Invite unlimited team members to collaborate in a shared, efficient workspace.',
            'Simple AWS Deployment: Deploy Retrohook into your AWS account with ease.',
            'Production Environment: Create a single production environment for your organization. (more can be purchased)',
            'Secure Connectivity: Generate and establish up to 5 IPSEC VPN Tunnels for robust and secure remote connections.',
          ];
        } else if (product.product[0].name == 'Developer Plan' && product.product[0].active) {
          product.features = [
            'Effortless Serverless Healthcare Messaging: Experience seamless hl7v2 communication without the complexities of server management',
            'Test Workflows: Design and test up to 5 non-PHI workflows, perfect for experimentation and learning.',
            'Developer Workspace: Collaborate effectively with a 3-seat workspace designed for dynamic development teams.',
            'Message Allocation: Benefit from 100 messages per month, allowing for ample testing and development.',
            'Community Support: Gain insights and assistance from a community that understands your development needs.',
          ];
        }
        return product;
      });
      const products: any = await Promise.all(promises).then((res) => res);
      const filtered = products.filter((product: any) => product.product[0].active !== false && product.active !== false);
      setProducts(filtered.sort((a: any, b: any) => (a.unit_amount > b.unit_amount ? 1 : -1)));
    }
  };
  const selectProduct = async (product: any, qp: number) => {
    if (product.product[0].name !== 'Enterprise Plan' && product.product[0].name !== 'Developer Plan') {
      if (props.account.type !== 'aws') {
        await checkoutStripe(product.id, qp);
        setSelectedProduct(product);
        setPaymentModal(!paymentModal);
      } else {
        window.location.replace(awsProductURL);
      }
    }
    if (product.product[0].name === 'Enterprise Plan') {
      setSelectedProduct(product);
      setContactModal(!contactModal);
    }
  };
  const getStripeCustomer = async () => {
    const cst: any = await get(`account/get-stripe-customer/${props.account.customerId}`);
    if (response.ok) {
      return cst.customer;
    }
  };
  const getCustomerPortal = async () => {
    if (props.account.customerIdentifier) {
      if (props.account.type === 'stripe') {
        const customerPortal: any = await get(`account/generate-stripe-customer-portal-session/${props.account.customerIdentifier}`);
        if (response.ok) window.location.replace(customerPortal.portalSession.url);
      }
      if (props.account.type === 'aws') {
        window.location.replace(awsProductURL);
      }
    }
  };
  const createStripeCustomer = async () => {
    const body = { email: props.selectedWorkspace.email };
    const newCustomer: any = await post(`account/create-stripe-customer/${props.user.sub}`, body);
    if (response.ok) {
      return newCustomer.customer;
    }
  };
  const checkoutStripe = async (priceId: string, qp: number) => {
    let { customerId } = props.account;
    if (props.account.customerId) {
      const cst = await getStripeCustomer();
      customerId = cst.id;
      setCustomer(cst);
    } else {
      const cs = await createStripeCustomer();
      customerId = cs.id;
      setCustomer(cs);
    }
    const body = { priceId, qp, customerId, account: props.account };
    const checkout: any = await post(`account/generate-stripe-checkout-session/${props.user.sub}`, body);
    if (response.ok) {
      window.location.replace(checkout.session.url);
    }
  };
  const handleNewUserFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...invite, firstName: event.target.value };
    setInvite(updated);
  };
  const handleNewUserLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...invite, lastName: event.target.value };
    setInvite(updated);
  };
  const handleNewUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...invite, email: event.target.value };
    setInvite(updated);
  };
  const handleMessageIDValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...workspace, hash: { ...workspace.hash, value: event.target.value } };
    setWorkspace(updated);
  };
  const handleMessageIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...workspace, hash: { ...workspace.hash, location: event.target.value } };
    setWorkspace(updated);
  };
  const handleOrganizationValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedWorkspace = {
      ...workspace,
      name: event.target.value,
    };
    setWorkspace(updatedWorkspace);
  };
  const openPermissions = (user: any) => {
    setSelectedUser(user);
    setTogglePermissions(true);
  };
  const closePermissions = () => {
    setTogglePermissions(false);
    setSelectedUser({ permissions: basePermissions, email: '', name: '', id: '' });
  };
  const clearInvite = () => {
    setInvite({ firstName: '', lastName: '', email: '', tempPass: '', permissions: basePermissions });
  };
  const addUserToWorkspace = async (slug: string) => {
    if (!invite.firstName || !invite.lastName || !invite.email) {
      const ea: any = 'MissingInviteFields';
      setError(ea);
      return;
    }
    invite.tempPass = `${(Math.random() + 1).toString(36).substring(6)}-HL7!`;
    const body = { workspace, invite, account: props.account };
    let usersMax;
    if (props.account.quantityPurchased < 1) usersMax = 5;
    else usersMax = props.account.quantityPurchased * 10;
    if (workspace.accounts.length >= usersMax) {
      const er: any = 'AccountUserLimitAlert';
      setError(er);
    } else {
      const newAccount: any = await post(`account/workspace-invite/${slug}`, body);
      if (newAccount) {
        setWorkspace(newAccount.workspace);
        props.setAccount(newAccount.account);
        props.notify({ message: '📤 Invite Sent' });
      }
    }
    setLoadingType('');
    clearInvite();
  };
  const removeUserFromWorkspace = async (se: any) => {
    const params = { se, workspaceParams: workspace, account: props.account };
    const rsp = await post(`account/workspace-remove/${props.user.sub}`, params);
    if (response && rsp) {
      props.setSelectedWorkspace(rsp.data);
      setWorkspace(rsp.data);
      props.notify({ message: 'User Removed From Workspace' });
    }
  };
  const updatePermissions = (resource: string, permission: string) => {
    selectedUser.permissions[resource][permission] = !JSON.parse(selectedUser.permissions[resource][permission]);
    const accounts = workspace.accounts.map((account: any) => {
      if (account.email === selectedUser.email) {
        if (account.permissions[resource][permission] === true || account.permissions[resource][permission] === 'true') {
          account.permissions[resource][permission] = true;
        } else {
          account.permissions[resource][permission] = false;
        }
      }
      return account;
    });
    setWorkspace({ ...workspace, accounts });
  };
  const savePermissions = async () => {
    const rsp = await post(`account/update-workspace/${props.selectedWorkspace.PK}`, { account: props.account, workspace: props.selectedWorkspace });
    if (response.ok && rsp) {
      props.notify({ message: 'Permissions Updated' });
    }
    closePermissions();
  };
  const saveWorkspace = async (ws: any) => {
    const rsp = await post(`account/update-workspace/${props.user.sub}`, { account: props.account, workspace: ws });
    if (response.ok && rsp.data) {
      setWorkspace(rsp.data.workspace);
      props.notify({ message: 'Workspace Updated' });
    }
  };

  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        if (props.myWorkspace?.SK) {
          const myWorkspace = await fetchMyWorkspace(props.myWorkspace?.SK);
          if (myWorkspace) setWorkspace(myWorkspace);
        }
      } catch (e: any) {
        console.error('Error fetching workspace:', e);
      }
    };
    fetchWorkspace();
  }, [props.myWorkspace, props.account]);

  useEffect(() => {
    getProducts();
  }, []);

  return {
    subUx,
    setSubUx,
    error,
    setError,
    products,
    contactModal,
    setContactModal,
    paymentModal,
    setPaymentModal,
    selectedProduct,
    setSelectedProduct,
    togglePermissions,
    setTogglePermissions,
    selectedUser,
    setSelectedUser,
    invite,
    setInvite,
    customer,
    setCustomer,
    billingUX,
    setBillingUX,
    loadingType,
    setLoadingType,
    quantityPurchased,
    setQuantityPurchased,
    workspace,
    setWorkspace,
    getProducts,
    selectProduct,
    getStripeCustomer,
    myWorkspace: props.myWorkspace,
    selectedWorkspace,
    getCustomerPortal,
    createStripeCustomer,
    checkoutStripe,
    handleNewUserFirstNameChange,
    handleNewUserLastNameChange,
    handleNewUserEmailChange,
    handleMessageIDValueChange,
    handleMessageIDChange,
    handleOrganizationValueChange,
    openPermissions,
    closePermissions,
    clearInvite,
    addUserToWorkspace,
    removeUserFromWorkspace,
    updatePermissions,
    savePermissions,
    saveWorkspace,
    loading,
    awsProductURL,
  };
};

export default useWorkspace;
